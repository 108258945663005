import React, { useState } from 'react';
import './index.css';
import { Button, Checkbox, Switch } from '@mui/material';

export default function SensorsListMenu({ availableSensors, showAverageSensorsData, setShowAvergaeSensorsData, setAvailableSensors, open, setOpen }) {
	const [sensorsCopystate, setSensorsCopyState] = useState([...availableSensors]);
	const [averageCopyState, setAverageCopyState] = useState(showAverageSensorsData);
	const showAvergaeValuesCb = () => {
		setAverageCopyState(!averageCopyState);
	};

	const updateCb = () => {
		setAvailableSensors(sensorsCopystate);
		setShowAvergaeSensorsData(averageCopyState);
		setOpen(!open);
	};

	const updateSensorStatus = (value) => {
		let sensorsCopy = [...sensorsCopystate];
		const index = sensorsCopy.findIndex((el) => el.artId === value.artId);
		if (index >= 0) {
			sensorsCopy[index].selected = !sensorsCopy[index].selected;
			setSensorsCopyState(sensorsCopy);
		}
	};

	const selectAllSensors = () => {
		let sensorsCopy = sensorsCopystate.map((el) => ({ ...el, selected: true }));

		setSensorsCopyState(sensorsCopy);
	};

	const allSensorsChecked = () => {
		let numberOfSelected = 0;
		sensorsCopystate.forEach((element) => {
			if (element.selected) {
				numberOfSelected++;
			}
		});

		return numberOfSelected === sensorsCopystate.length;
	};

	return (
		<div onClick={(e) => e.stopPropagation()} className="sensors-menu-content">
			<div style={{ padding: '3px 11px' }}>
				{'Average'}

				<Switch checked={averageCopyState} onChange={showAvergaeValuesCb} />
			</div>

			<div className="sensors-list" style={{ height: '176px' }}>
				<span className="sensors-menu-item">
					<Checkbox onChange={selectAllSensors} checked={allSensorsChecked()} />
					{'Select all'}
				</span>

				{sensorsCopystate.map((el, index) => (
					<span key={index} onClick={() => updateSensorStatus(el)} className="sensors-menu-item">
						<Checkbox checked={el.selected} />
						{el.artId}
					</span>
				))}
			</div>
			<div style={{ padding: '6px 8px', textAlign: 'center' }}>
				<Button variant="contained" onClick={updateCb}>
					{' '}
					{'Update'}{' '}
				</Button>
			</div>
		</div>
	);
}
