import React, { useMemo } from 'react';
import { Box, Popper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SensorsEmptyState from './components/emptyState/SensorsEmptyState';
import './components/index.css';

import SensorsList from '../../../../shared/icons/sensors/listMenu.png';
import SensorsListMenu from './components/sensorsListMenu/SensorsListMenu';
import SensorsCharts from './components/sensorsCharts/SensorsCharts';
import { getArtsDataToDisplay } from '../../../../services/artServices';

export default function SensorsData({ artData, availableSensors, showAverageSensorsData, setShowAvergaeSensorsData, setAvailableSensors, dateRange }) {
	const sensorsTitle = 'Sensors';
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showContent, setShowContent] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(anchorEl ? anchorEl : event.currentTarget);
		setOpen(!open);
	};
	const id = open ? 'simple-popper' : undefined;
	const sensorsData = useMemo(() => getArtsDataToDisplay([...artData], [...availableSensors], showAverageSensorsData, dateRange), [availableSensors, showAverageSensorsData, dateRange, dateRange.id, artData]);

	const iconsListContainerStyle = { width: '143px', height: '17px', display: 'flex', alignItems: 'center' };
	const IconList = (
		<div style={iconsListContainerStyle} onClick={handleClick}>
			<img height={'16px'} width={'16px'} src={SensorsList} alt="empty-list" />
			<span style={{ marginLeft: '3px' }}>
				{availableSensors.length}
				{sensorsTitle}
			</span>

			<ExpandMoreIcon style={{ height: '16px', width: '16px' }} />

			<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
				<Box style={{ width: '176px', height: '284px', flexDirection: 'column', background: 'white' }}>
					<SensorsListMenu availableSensors={availableSensors} showAverageSensorsData={showAverageSensorsData} setShowAvergaeSensorsData={setShowAvergaeSensorsData} setAvailableSensors={setAvailableSensors} open={open} setOpen={setOpen} />
				</Box>
			</Popper>
		</div>
	);

	let emptyStateTitle = 'No data for the selected sensors';
	if (!sensorsData && availableSensors) {
		emptyStateTitle = 'No selected data sources to display';
	}
	return (
		<div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.16)', borderTop: ' 1px solid rgba(0, 0, 0, 0.16)' }}>
			<div className="sensors-data-container" onClick={() => setShowContent(!showContent)}>
				{' '}
				<ExpandMoreIcon style={{ height: '16px', width: '16px', transform: showContent ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
				{sensorsTitle}
				<div style={{ width: '100%' }} className="sensors-menu">
					{IconList}
				</div>
			</div>

			{sensorsData ? <SensorsCharts sensorsData={sensorsData} showContent={showContent} /> : <SensorsEmptyState emptyStateTitle={emptyStateTitle} />}
		</div>
	);
}
