import React from 'react';
import { VictoryChart, VictoryClipContainer, VictoryLabel, VictoryLine } from 'victory';
import { ART_CHART_DATE_FILL_COLOUR, ART_CHART_LABELS_FONT_FAMILY, ART_CHART_LABELS_FONT_SIZE, ART_CHART_LAST_DATE_FILL_COLOUR } from '../../../../../Constants';

export default function LinesChart({ victoryLGVTheme, width, height, sensorsData, getLineStroke, containerComponent, padding, clipPadding }) {
	let lastValue = '';
	if (sensorsData && sensorsData.length && sensorsData[0] && sensorsData[0].data && sensorsData[0].data.length) {
		lastValue = sensorsData[0].data[0].date;
	}

	const isDatesEqual = (a, b) => {
		return a.getTime() === b.getTime();
	};
	return (
		<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={width} height={height} domainPadding={10} padding={padding} containerComponent={containerComponent}>
			{sensorsData
				? sensorsData.map(({ data, paramName, artId }, index) => (
						<VictoryLine
							key={index}
							groupComponent={<VictoryClipContainer clipPadding={clipPadding} />}
							style={{
								data: { stroke: getLineStroke(index, paramName), strokeWidth: 2, strokeDasharray: '3,3', strokeLinecap: 'round' },
								labels: {
									fill: ({ datum }) => (isDatesEqual(datum.x, lastValue) ? ART_CHART_LAST_DATE_FILL_COLOUR : ART_CHART_DATE_FILL_COLOUR),
									fontSize: ART_CHART_LABELS_FONT_SIZE,
									fontFamily: ART_CHART_LABELS_FONT_FAMILY,
								},
							}}
							data={data.map((el) => ({ ...el, x: el.date, y: el[paramName] }))}
							labels={artId}
							labelComponent={<VictoryLabel angle={0} textAnchor="end" dx={20} />}
						/>
				  ))
				: ''}
		</VictoryChart>
	);
}
