import React, { useEffect, useState } from 'react';
import { VictoryChart, VictoryAxis, LineSegment, VictoryVoronoiContainer, VictoryScatter, VictoryLine, VictoryClipContainer } from 'victory';
import { victoryLGVTheme } from '../../../../victoryLGVTheme';
import _ from 'lodash';
import { getYearlyData, getYearsToDisplay, tickFormatService } from '../../../../../services/trendsServices';
import { format } from 'date-fns';
import { Constants, TRENDS_LINES_YEARLY_COLOURS } from '../../../../Constants';
import { Checkbox, FormControlLabel } from '@mui/material';
import './index.css';

export default function ViewTrend({ adjustForDataGaps, trendType, current, dateRange, victoryArea, victoryAreaWithEstimates, victoryAreaWithPrediction, bloomPredictedData, activitiesList, showTreatmentInGrapth, setShowTreatmentInGraph, updateSelectedAlertDate, user, labelComponent, monthlyData }) {
	let unit = '';
	if (user && user.units) {
		unit = Constants.UNITS[user.units].Prescription.label;
	}
	const [yearlyData, setYearlyData] = useState(undefined);

	useEffect(() => {
		if (!yearlyData) {
			let result = getYearlyData(monthlyData);
			if (result) {
				setYearlyData(result);
			}
		}
	});

	const _labelComponent = labelComponent(4, true, true);

	const actualData = adjustForDataGaps(trendType, false, current),
		estimatedData = adjustForDataGaps(trendType, true, current);
	let treatmentDates = undefined;
	let treatmentDatesWithValues = undefined;
	const getActivitiesDates = (treatmentDates, activitiesList, actualData) => {
		if (!treatmentDates || treatmentDates.length === 0) {
			if (activitiesList && actualData) {
				let lineDataCopy = [];
				for (const element of activitiesList) {
					const formattedDate = format(new Date(element.startTime), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
					const result = actualData.findIndex((el) => format(el.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT) === formattedDate);
					if (result >= 0) {
						lineDataCopy.push({ x: new Date(element.startTime), y: 0 });
					}
				}
				if (lineDataCopy.length > 0) {
					return lineDataCopy;
				}
			}
		}
		return undefined;
	};

	const labels = (datum) => {
		if (!activitiesList) return '';
		let date = format(new Date(datum.x), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		const index = activitiesList.findIndex((el) => format(new Date(el.startTime), Constants.DATE_FORMATS.SHORT_DATE_FORMAT) === date);
		if (index >= 0 && activitiesList[index]) {
			return [`${new Date(datum.x).toLocaleDateString()}`, `${activitiesList[index].metadata.formula}`, `Dosage of ${activitiesList[index].metadata.dosage * Constants.UNITS[user.units].Prescription.factor} ${unit}`, `Region: ${activitiesList[index].zone}`];
		}
	};
	const getActivitiesDateswithValues = (treatmentDates, activitiesList, actualData) => {
		if (!treatmentDates || treatmentDates.length === 0) {
			if (activitiesList && actualData) {
				let lineDataCopy = [];
				for (const element of activitiesList) {
					const formattedDate = format(new Date(element.startTime), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
					const result = actualData.findIndex((el) => format(el.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT) === formattedDate);
					if (result >= 0) {
						const value = actualData[result] && actualData[result].BloomLevel;

						lineDataCopy.push({ ...actualData[result], x: new Date(element.startTime), y: value });
					}
				}
				if (lineDataCopy.length > 0) {
					return lineDataCopy;
				}
			}
		}
		return undefined;
	};

	const viewTrend = () => {
		// const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi'),
		if (showTreatmentInGrapth) {
			treatmentDates = getActivitiesDates(treatmentDates, activitiesList, actualData);
			treatmentDatesWithValues = getActivitiesDateswithValues(treatmentDatesWithValues, activitiesList, actualData);
		} else {
			treatmentDates = undefined;
			treatmentDatesWithValues = undefined;
		}

		const splitArrayByContiguousDates = (data) => {
				if (data.length === 0) {
					return [];
				}

				const result = [];
				let currentArray = [data[0]];

				for (let i = 1; i < data.length; i++) {
					const currentDate = new Date(data[i].date);
					const previousDate = new Date(data[i - 1].date);

					// Calculate the difference in days between current and previous date
					const timeDiff = Math.abs(currentDate - previousDate);
					const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

					if (data[i].BloomLevel === 0) {
						data[i].BloomLevel = data[i - 1].BloomLevel;
					}

					// If the difference is 1 day, dates are contiguous; add to the current array
					if (daysDiff === 1) {
						currentArray.push(data[i]);
					} else {
						// Dates are not contiguous; push the current array and start a new one
						result.push(currentArray);
						currentArray = [data[i]];
					}
				}

				// Push the last array
				result.push(currentArray);

				return [result];
			},
			// containerComponent = <VictoryZoomVoronoiContainer responsive={true} zoomDimension="x" />,
			containerComponent = <VictoryVoronoiContainer />,
			isYearly = dateRange.id === 'Yearly',
			victoryAxis = (
				<VictoryAxis
					orientation="bottom"
					fixLabelOverlap={true}
					tickValues={[...estimatedData, ...bloomPredictedData].map((_datum) => _datum?.date)}
					tickFormat={(datum) => tickFormatService(datum, dateRange)}
					style={{
						ticks: { stroke: 'black', size: 5 },
						tickLabels: { fill: 'black', fontSize: 15 },
					}}
					gridComponent={<LineSegment style={{ stroke: 'none' }} />}
				/>
			),
			victoryDependentAxis = (
				<VictoryAxis
					dependentAxis
					tickFormat={(datum) => `${datum === 0 ? datum.toFixed(1) : datum}`}
					fixLabelOverlap={true}
					style={{
						axis: { stroke: { fill: 'none' } },
						tickLabels: { fill: 'black', fontSize: 15 },
					}}
				/>
			),
			getVictoryScatterIcon = () => {
				return 'circle';
			},
			yearsLength = yearlyData?.length - 1 || 0,
			yearsToDisplay = yearsToDisplay ? yearsToDisplay : getYearsToDisplay(yearlyData),
			getLineStroke = (index) => {
				return TRENDS_LINES_YEARLY_COLOURS[index !== yearsLength ? index : 'Average'];
			},
			yearlyLines = () => {
				if (!yearlyData) return '';
				return (
					<>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '4px 8px', paddingLeft: '26px' }}>
							{yearsToDisplay.map((el, index) => (
								<span key={index} style={{ display: 'flex', height: '15px', alignItems: 'center', marginRight: '8px' }}>
									{' '}
									{el.title} <span style={{ height: '4px', width: '11px', background: el.color, display: 'block', marginLeft: '4px' }}></span>{' '}
								</span>
							))}
						</div>

						<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={600} height={240} domainPadding={10} padding={{ left: 70, right: 25, top: 30, bottom: 30 }} containerComponent={containerComponent}>
							{yearlyData
								? yearlyData.map((data, index) => (
										<VictoryLine
											key={index}
											groupComponent={<VictoryClipContainer clipPadding={{ top: 5, right: 10 }} />}
											style={{ data: { stroke: getLineStroke(index), strokeWidth: 2, strokeDasharray: index === yearsLength ? '3,3' : '1', strokeLinecap: 'round' } }}
											data={data.map((el) => ({ ...el, x: el.x, y: el[trendType] }))}
										/>
								  ))
								: ''}
						</VictoryChart>
					</>
				);
			},
			yearlyChart = <>{yearlyLines()}</>,
			victoryChart = (
				<>
					<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={600} height={240} domainPadding={10} padding={{ left: 70, right: 25, top: 30, bottom: 30 }} containerComponent={containerComponent}>
						{victoryDependentAxis}
						{victoryAxis}
						{!_.includes(['BloomLevel', 'Carbon'], trendType) && victoryArea(trendType, 'trends', [actualData])}
						{trendType === 'BloomLevel' && victoryAreaWithEstimates(trendType, 'trends', true, estimatedData)}
						{trendType === 'BloomLevel' && victoryAreaWithPrediction(trendType, 'trends', true, [...estimatedData, ...bloomPredictedData])}
						{trendType === 'BloomLevel' && victoryAreaWithEstimates(trendType, 'trends', false, splitArrayByContiguousDates(_.reject(estimatedData, (_data) => _data.estimated)))}

						{treatmentDatesWithValues ? (
							<VictoryScatter
								symbol={({ datum }) => getVictoryScatterIcon(datum)}
								size={5}
								data={treatmentDates}
								style={{
									data: {
										fill: '#058ab5',
									},
								}}
								labels={({ datum }) => labels(datum)}
								labelComponent={_labelComponent}
								tickValues={treatmentDatesWithValues.map((_datum) => _datum?.date)}
								events={[
									{
										target: 'data',

										eventHandlers: {
											onClick: () => {
												return [
													{
														target: 'data',

														mutation: (props) => {
															if (props && props.datum && props.datum.x) {
																updateSelectedAlertDate(props.datum.x);
															}
														},
													},
												];
											},
										},
									},
								]}
							/>
						) : (
							''
						)}

						{treatmentDates && treatmentDates.length > 0
							? treatmentDates.map((el, index) => {
									return (
										<VictoryAxis
											dependentAxis
											key={index}
											label={''}
											style={{
												tickLabels: { fill: 'transparent', display: 'none' },
												ticks: { stroke: 'transparent', size: 0 },
												grid: { stroke: ({ tick }) => 'transparent' },
												axis: { stroke: 'rgba(169, 169, 169, 0.75)', strokeWidth: '1', strokeDasharray: '4' },
											}}
											axisValue={el.x}
											standalone={true}
										/>
									);
							  })
							: ''}
					</VictoryChart>
				</>
			);
		return (
			<React.Fragment key={'trends'}>
				{isYearly ? yearlyChart : victoryChart}

				{/* <div className="trendsDateBy">{dateRange.title}</div> */}

				{isYearly ? '' : <FormControlLabel className="show-activities" control={<Checkbox sx={{ marginLeft: '16px' }} checked={showTreatmentInGrapth} onChange={() => setShowTreatmentInGraph(!showTreatmentInGrapth)} />} label="Show activities" />}
			</React.Fragment>
		);
	};
	return viewTrend();
}
